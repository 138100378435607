<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-9区域指标系统.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">区域指标系统</div>
            <div class="mobile-head-explain-content">全国某领先高端靶向制剂制造商</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">区域指标系统项目的建设目标是借助区域指标系统工具，完成基础数据管理、人员管理、区域设定管理及指标设定管理功能。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <!-- <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div> -->
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <!-- <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div> -->
        </div>
        <div class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-3-4管理功能.svg"/>
                    <div>
                        <div class="title1">管理功能</div>
                        <div class="title2">借助区域指标系统工具，完成基础数据管理、人员管理、区域设定管理及指标设定管理功能。</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-3-4数据功能.svg"/>
                    <div>
                        <div class="title1">数据功能</div>
                        <div class="title2">基于强大的元数据功能，结合多层级组织机构及角色/组的关联关系，业务人员可以通过配置，灵
                            活实现基础数据（医院，医生，科室等）管理，销售人员管理，各个层级区域的设定，各类指标管理，以及销量数据的拆分及汇总等。</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-3-4提高竞争力.svg"/>
                    <div>
                        <div class="title1">提高竞争力</div>
                        <div class="title2">针对医院和零售团队不同的业务需求，输出近20张业务核查表，从各个维度查看区域指标和实际销量，减少管理决策
                            中经验判断的风险和隐患，充分提高企业市场快速反应力与竞争力。</div>
                    </div>
                </div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 2,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 12rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 50rem;
        height: 35rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 6rem;
            height: 6rem;
            margin: 6rem 0 0 2rem;
        }
        .title1{
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 5.5rem 0 0 1.2rem;
        }
        .title2{
            max-width: 38rem;
            font-size: 2.3rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 3rem;

            margin: 1.2rem 0 0 1.2rem;
        }
    }
}
</style>